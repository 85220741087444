import React, { useCallback } from "react"

import { useApp } from "../../../hooks/useApp"

export const withSwatches = Component => ({ name = "Swatches", siblings, selectOptions = [], handle }) => {
  const {
    config: {
      settings: {
        routes: { PRODUCT },
        tags: { swatchColor },
        constraints: { COLOUR },
      },
    },
  } = useApp()

  const getSwatchColour = useCallback(
    tags => {
      return tags.find(tag => tag.includes(swatchColor))?.split(":")?.[1]?.replace("/", "_")
    },
    [swatchColor]
  )

  const swatches = siblings?.filter((sibling: any) => sibling?.shopify?.shopifyPublished)?.map(({ title, tags, shopify }) => ({
    title,
    handle: shopify?.shopifyHandle,
    url: `${PRODUCT}/${shopify?.shopifyHandle}`,
    image: `https://res.cloudinary.com/propel-group/Driza%20Bone%20Swatch/${getSwatchColour(tags)}`,
  })).filter((swatch, index, self) => index === self.findIndex(s => s.handle === swatch.handle))

  const selectedColour = selectOptions?.find(option => option.name === COLOUR)?.value

  Component.displayName = name
  return <Component swatches={swatches} colour={selectedColour} handle={handle} />
}
